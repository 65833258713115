import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export const banner = {
    title: "クッキー＆プライバシーポリシー",
    tagline: "GDPR、CCPAなどの規制を遵守しながら、必要に応じてドキュメントをカスタマイズ可能"
}

export const features = {
    image: (<StaticImage title='クッキー＆プライバシーポリシー' src={"../../static/images/feature/cookie-privacy-policy/featureImage.png"} width={530} quality={100} alt="Cookie Privacy Policy Feature Image"  />),
    contents: [
        {
            title: "クッキー＆プライバシーポリシージェネレーター",
            paragraph: <p>GDPR、CCPA、LGPDを遵守したプライバシーポリシーを生成し、先進的な自動クッキーバナーソリューションで安心を得ることができます。Secure Privacyのプライバシーポリシージェネレーターは、貴社独自のビジネスニーズと遵守義務を満たすように、ウェブサイトのプライバシーポリシーをカスタマイズできます。</p>
        },
    ]
}


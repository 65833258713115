import React from "react";

type TwoColumnContentSectionProps = {
  isColReverse: boolean,
  contents: {
    title: string,
    paragraph: React.ReactNode
  }[],
  image: React.ReactNode
};

const TwoColumnContentSection: React.FC<TwoColumnContentSectionProps> = ({ isColReverse, contents, image }) => (
  <div className={`flex flex-col md:flex-row px-8 items-center gap-x-14 gap-y-8 ${isColReverse && "md:flex-row-reverse"}`}>
    <div className="flex-1">
      {
        contents.map((content, index) => (
          <div key={index}>
            <h2 className="mb-6 leading-8 dx-h3 text-dsc-theme-title">
              {content.title}
            </h2> 
            <div className="mb-6 leading-6 dx-p2 text-dsc-theme-muted-500 [&_ul]:list-disc [&_ul]:pl-6 [&_ul]:mt-4">
              {content.paragraph}
            </div>
          </div>
        ))
      }
    </div>
    <div className="flex-1 w-full h-full" >
      {image}
    </div>
  </div>
);

export default TwoColumnContentSection;

import * as React from "react";
import Layout from "../../components/common/layout";
import BannerWithTextBg from "../../components/common/banner-with-text-bg";
import TwoColumnContentSection from "../../components/common/banner-with-two-columns";
import { features, banner } from '../../contents/cookie-privacy-policy';
import Meta from "../../components/common/meta/SEO";

const CookiePrivacyPolicy = () => {
  return (
    <Layout>
        <BannerWithTextBg contents={banner} />
        <div className="mt-20 mb-24">
            <TwoColumnContentSection image={features.image} contents={features.contents} isColReverse={false} />
        </div>
    </Layout>
  );
};

export default CookiePrivacyPolicy;

export const Head = ({ location }) => {
  const meta = {
    title: "クッキーとプライバシーポリシー｜Secure Privacy正規販売代理店 DXable",
    description: "Secure Privacyのクッキーバナーソリューションで、GDPR、CCPA、LGPDに準拠したプライバシーポリシーを作成し、安心・安全に利用することができます。",
    pathname: location.pathname,
  }
  return (<Meta meta={meta} />)
}
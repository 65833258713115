import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Button from "../button";

type ContentType = {
  contents: {
    title: string,
    tagline: string,
  }
}

const BannerWithTextBg: React.FC<ContentType> = ({ contents }) => {
  return (
    <section className="py-16 px-6 flex text-white flex-col w-full bg-cover gap-4 text-center relative">
      <div className="absolute w-full h-full left-0 top-0 -z-10">
        <StaticImage src="../../../../static/images/gradient.png" alt="Gradient Background" className="w-full h-full" quality={100} />
      </div>
      <h1 className="dx-h1 leading-[50px]">{ contents.title }</h1>
      <p className="dx-p1 leading-7 mb-2">{ contents.tagline }</p>
      <div className="mx-auto max-w-max text">
        <Button to="/contact-us/" variant="normal">お問い合わせ</Button>
      </div>
    </section>
  );
};
export default BannerWithTextBg;
